<template>
  <div class="page-news">
    <!--        <div class="page-header large-header newsletter">-->
    <!--          <div class="header-layer">-->
    <!--            <div class="container">-->
    <!--              <div class="header-title">-->
    <!--                <small>Daily newsletter</small>-->
    <!--                <h3>Товхимол</h3>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->

    <div class="news-latter-section">
      <div class="container">
        <!--        <h1 class="font-bold text-[19px] my-4 text-left color-white">Онцлох нийтлэл</h1>-->
        <div class="blog2">
          <div
              class="grid sm:gap-x-8 md:gap-x-12 gap-x-6 sm:gap-y-6 md:gap-y-8 gap-y-4 sm:grid-cols-3 md:grid-cols-4 grid-cols-2 md:grid-rows-2 ">
            <div v-for="(item, index) in newsSpecial" :key="item.id"
                 :class="index === 0 ? 'item large md:col-span-2 md:row-span-2 col-span-1':'item col-span-1' ">
              <div class="img-cover" :style="`background-image: url('${imageUrl(item.image)}')`"
                   v-if="item.image"></div>
              <div class="img-cover" style="background-image: url('../../../public/img/logo.png')" v-else></div>

              <div class="is-free" v-if="item.is_free === 1">Үнэгүй</div>

              <!--              <img :src="imageUrl(item.image)" class="img-fluid" alt="">-->
              <div class="bottom-fade"></div>
              <div class="title">
                <!--                <h6>Rental</h6>-->
                <h4>{{ item.title }}</h4>
              </div>
              <div class="curv-butn icon-bg">
                <a href="javascript:void(0)" @click="detail(item)" class="vid">
                  <div class="icon">
                    <i class="icon-show"><span>{{
                        item.view_date ? item.view_date.toString().substring(8, 10) : item.created_at.toString().substring(8, 10)
                      }}<br><i>{{
                          months[item.view_date ? parseInt(item.view_date.toString().substring(5, 7)) - 1 : parseInt(item.created_at.toString().substring(5, 7)) - 1]
                        }}</i></span>
                    </i><i class="isax isax-arrow-right-1 icon-hidden"></i>
                  </div>
                </a>

                <div class="br-left-top">
                  <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-11 h-11">
                    <path d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                          fill="#000000"></path>
                  </svg>
                </div>
                <div class="br-right-bottom">
                  <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-11 h-11">
                    <path d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                          fill="#000000"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6">
          <div v-if="newsTags?.length > 0">
            <div class="news-latter-tags">
              <div :class="selectedTag === tag.id ? 'tags tags-active': 'tags' " v-for="tag in newsTags" :key="tag.id"
                   @click="setSelectedTag(tag.id)">
                {{ tag.title }}
              </div>
            </div>
          </div>

          <!--          <h1 class="font-bold text-[19px] my-6">Шинээр нэмэгдсэн</h1>-->
          <div
              class="blog1 grid sm:grid-cols-3 grid-cols-2 sm:gap-x-8 md:gap-x-12 gap-x-6 sm:gap-y-6 md:gap-y-8 gap-y-4 mt-6"
              v-if="!newsLoading">
            <div class="item" v-for="(item) in news" :key="item.id">
              <div class="is-free" v-if="item.is_free === 1">Үнэгүй</div>
              <div class="img">
                <div class="img-cover"
                     :style="item?.image?`background-image: url('${imageUrl(item?.image)}')`:''"></div>

              </div>
              <div class="wrapper">
                <div class="date"><a href="javascript:void(0)">{{
                    item.view_date ? item.view_date : item.created_at
                  }}</a></div>
                <div class="con">
                  <!--                  <div class="category" v-if="item?.tags?.length >0">-->
                  <!--                    <a href="#" v-for="t in item.tags" :key="t.id"><i class="ti-user"></i>{{ t.title }}</a>-->
                  <!--                  </div>-->
                  <div class="text"><a href="javascript:void(0)" @click="detail(item)">{{ item.title }}</a></div>
                  <a href="javascript:void(0)" @click="detail(item)" class="icon-btn">
                    <i class="isax isax-arrow-right-1"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="animate-pulse" v-else>
            <div
                class="blog1 grid sm:grid-cols-3 grid-cols-2 sm:gap-x-8 md:gap-x-12 gap-x-6 sm:gap-y-6 md:gap-y-8 gap-y-4">
              <div
                  v-for="n in 6"
                  :key="n"
                  class=" bg-gray-500 rounded mb-4 h-6 w-full"
                  style="height: 300px"

              >
              </div>
            </div>

          </div>
          <div class="flex items-center justify-center space-x-2 mt-4" v-if="totalPages > 1">
            <button
                @click="goToPage(currentPage-1)"
                :disabled="currentPage === 1"
                class="px-3 py-1 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 text-white"
            >
              Өмнөх
            </button>
            <button
                v-for="page in totalPages"
                :key="page"
                @click="goToPage(page)"
                :class="['px-3 py-1 rounded text-white',currentPage === page ? 'bg-customOrange' : 'bg-gray-700 hover:bg-gray-600']"
            > {{ page }}
            </button>

            <button
                @click="goToPage(currentPage+1)"
                :disabled="currentPage === totalPages"
                class="px-3 py-1 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 text-white"
            >
              Дараах
            </button>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div>

    <Dialog v-model:visible="visible" :header="null"
            class="modal-news-latter-detail">
      <div class="news-latter-detail" v-if="newsDetail">
        <img src="../../assets/newslatter_header_banner.jpg" alt="" width="100%" height="80"
             class="image"/>
        <div class="detail-container">
          <h1 class="title" v-if="newsDetail.title">{{ newsDetail.title }}</h1>
          <div class="powered-by" v-if="newsDetail?.powered_by_image">
            <h1>Powered by :</h1>
            <a v-if="newsDetail?.powered_by_link" :href="newsDetail?.powered_by_link" target="_blank"><img
                :src="imageUrl(newsDetail?.powered_by_image)" width="40" height="40" alt=""/></a>
            <img v-else :src="imageUrl(newsDetail?.powered_by_image)" width="40" height="40" alt="powered_by_image"/>
          </div>
          <div class="news-latter-tag" v-if="newsDetail?.tags?.length>0">
            <div class="tags" v-for="t in newsDetail.tags" :key="t.id">
              {{ t.title }}
            </div>
          </div>
          <div class="three-words" v-if="newsDetail.three_sentences">
            <h1> Гурван үгээр</h1>
            <div class="words" v-html="newsDetail.three_sentences" v-if="newsDetail.three_sentences"></div>
          </div>
          <divider v-if="newsDetail.three_sentences"/>
          <img v-if="newsDetail?.exchange_image" :src="imageUrl(newsDetail?.exchange_image)" class="exchange-image"
               alt="exchange_image"/>

          <divider v-if="newsDetail?.exchange_image"/>
          <div class="news-latter-content" v-if="newsDetail.content?.length>0">
            <div class="content-detail" v-for="(c, index) in newsDetail.content" :key="index">
              <h3 class="content-detail-title">{{ c.title }}</h3>
              <div v-html="c.body" v-if="c.body" class="content-detail-body"></div>
            </div>
          </div>
          <divider/>
          <div class="news-latter-content-footer">
            <div class="social-icons">
              <ul class="list-inline">
                <li v-if="socialInfo?.facebook"><a :href="socialInfo?.facebook" target="_blank"> <i
                    class="ui uil-facebook"></i></a></li>
                <li v-if="socialInfo?.instagram"><a :href="socialInfo?.instagram" target="_blank"><i
                    class="ui uil-instagram-alt"></i></a></li>
                <li v-if="socialInfo?.twitter"><a :href="socialInfo?.twitter" target="_blank"><i
                    class="ui uil-twitter"></i></a></li>
                <li v-if="socialInfo?.youtube"><a :href="socialInfo?.youtube" target="_blank"><i
                    class="ui uil-youtube"></i></a></li>
                <li v-if="socialInfo?.linkedin"><a :href="socialInfo?.linkedin" target="_blank"><i
                    class="ui uil-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
          <divider v-if="newsDetail?.prepared || newsDetail?.editor"/>
          <div class="news-latter-content-footer">
            <div class="editor" v-if="newsDetail?.prepared"><h4>Дугаарыг бэлтгэсэн :</h4>
              <p>{{ newsDetail?.prepared }}</p></div>
            <div class="editor" v-if="newsDetail?.editor"><h4>Редактор :</h4>
              <p>{{ newsDetail?.editor }}</p></div>
          </div>

        </div>

      </div>
    </Dialog>

  </div>
  <ConfirmDialog></ConfirmDialog>
  <Dialog :showHeader="false" class="login-page-modal" v-model:visible="isAuthVisible" :modal="true"
          :dismissableMask="true"
          position="center" :closable="true" :style="{width: '600px'}">
    <Login :hide="hideAuthModal"/>
  </Dialog>
</template>

<script>
import axios from "axios";
import config from "@store/_boot/config";
import {imageUrl} from "@utils";
import moment from 'moment';
import {mapGetters} from "vuex";
import Login from "@pages/auth/login.vue";
import api from "@store/_boot/baseApi"

export default {
  name: 'NewsLatter',
  components: {Login},
  data() {
    return {
      isAuthVisible: false,
      visible: false,
      isLoading: true,
      isLoadingMore: false,
      hasMore: false,
      page: 1,
      news: [],
      newsSpecial: [],
      newsPerPage: '',
      totalNews: 0,
      currentPage: 1,
      lastPage: 0,
      newsDetail: null,
      newsTags: [],
      selectedTag: null,
      newsLoading: false,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      socialInfo: null,
      socials: window.init.socials,
      totalPages: 0,
    };
  },

  created() {
    this.getNews();
    this.getNewsSpecial();
    this.getNewsCategory();
    this.getInfo();
  },
  computed: {
    ...mapGetters(['user']),
    // totalPages() {
    //   return Math.ceil(this.totalNews / this.newsPerPage);
    // },
    lastPageColor() {
      return this.lastPage === this.currentPage ? 'grey' : '';
    },

  },
  methods: {
    showAuthModal() {
      this.isAuthVisible = true;
    },
    hideAuthModal() {
      this.isAuthVisible = false;
    },
    expireConfirmDialog() {
      this.$confirm.require({
        message: 'Та эрхээ сунгаж тус контентыг үзнэ үү !!',
        header: 'Эрх сунгах',
        rejectProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true
        },
        acceptProps: {
          label: 'Save'
        },
        acceptLabel: "Эрх сунгах",
        rejectLabel: "Болих",
        accept: () => {
          this.$router.push('/payment');
        },
      });
    },
    setSelectedTag(id) {
      this.selectedTag = id
      this.getNews()
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    getNewDetail(id) {
      axios.get(`${config.HOST}/api/web/all-news-latter/${id}`).then(({data}) => {
        this.newsDetail = data;
      })
    },
    imageUrl,
    getNewsSpecial() {
      axios.get(`${config.HOST}/api/web/all-news-latter-special`).then(({data}) => {
        if (data.status) {
          this.newsSpecial = data.data.data;
        }
      })
    },

    getNews() {
      this.newsLoading = true
      axios.get(`${config.HOST}/api/web/all-news-latter?tag_id=${this.selectedTag}&page=${this.currentPage}`).then(({data}) => {
        if (data.status) {
          // console.log('data', data)
          this.news = data.data.data;
          this.totalNews = data.data.total;
          this.currentPage = data.data.current_page;
          this.newsPerPage = data.data.per_page;
          this.lastPage = data.data.last_page;
          this.totalPages = data.data.last_page;
          this.newsLoading = false

        }
      })
    },
    getNewsCategory() {
      axios.get(`${config.HOST}/api/web/news-latter-tags`).then(({data}) => {

        if (data) {
          this.newsTags = data
        }
      })
    },
    goToPage(page) {
      this.currentPage = page;
      this.newsLoading = this;
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.getNews();
    },
    detail(item) {
      if (this.user == null) {
        this.showAuthModal()
      } else {
        if (item?.is_free === 1) {
          this.getNewDetail(item.id)
          this.visible = true;
        } else {
          if (this.user.days === 0) {
            this.expireConfirmDialog()
          } else {
            this.getNewDetail(item.id)
            this.visible = true;
          }
        }
      }
    },
    getInfo() {
      api.get(`/api/web/social-info`).then(({data}) => {
        if (data) {
          this.socialInfo = data
        }
      })
    },

  },

};
</script>

